import React, { useEffect, useState } from "react"
import { graphql } from "gatsby";
import SEO from "../components/seo"
import Img from "gatsby-image"
import { Footer } from "../components/footer/footer";
import Lottie from 'react-lottie';
import LogoGreyEdmond from "../components/svg/logo_grey";
import * as animationLogoIntro from "../components/animations/lottie/coignet_anim_logo.json"
import { Link } from "gatsby"
import { motion } from 'framer-motion';
import SvgCroix from "../components/svg/croix";
import BackgroundImage from 'gatsby-background-image'
import { LottieWrapper } from "../components/animations/lottie";
import {
    isBrowser,
    isMobile
} from "react-device-detect";

export const RealisationPage = ({ data }) => {

    const realisation = data.markdownRemark;
    const [WindowScope, setWindowScope] = useState();

    const AnimLogoIntro = {
        loop: false,
        autoplay: true,
        animationData: animationLogoIntro.default,
        rendererSettings: {
            renderMode: "SOFTWARE",
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const ProjectTransition = {
        initial: {
            scaleX: 0,
            originX: 0,
        },
        enter: {
            scaleX: 1,
            originX: 0,
            transition: {
                type: 'spring',
                damping: 40,
                stiffness: 200,
                duration: 0.1,
                when: "beforeChildren",
                staggerChildren: 0.1,
            },
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.3,
                when: "afterChildren",
            },
        },
    };

    const childrenAnim = {
        initial: {
            opacity: 0,
            originX: 0.5,
            originY: 0.5,
            scale: 0.8,
        },
        enter: {
            opacity: 1,
            scale: 1,
            originX: 0.5,
            originY: 0.5,
            transition: {
                type: "spring",
                damping: 10,
                mass: 0.5,
                duration: 0.3,
            }
        },
        exit: {
            opacity: 0,
            scale: 0.8,
        }
    }

    const childrenAnimOpacity = {
        initial: {
            opacity: 0,
            y: 40,
        },
        enter: {
            opacity: 1,
            y: 0,
            transition: {
                type: "tween",
                duration: 0.3,
            }
        },
        exit: {
            opacity: 0,
            y: 40,
        }
    }

    useEffect(() => {
        const windowGlobal = typeof window !== 'undefined' && window;
        setWindowScope(windowGlobal);
    }, [WindowScope]);

    return (
        <>
            <SEO title={realisation.frontmatter.titre} />
            <motion.div
                key={realisation.frontmatter.titre}
                initial="initial"
                animate="enter"
                exit="exit"
                variants={ProjectTransition}
                className="fixed overflow-y-scroll  overflow-x-hidden z-130 top-0 left-0 background-bluelight-edmond w-full h-full">
                <div className={` ${isBrowser ? "w-3/4" : "w-full"} transform translate-x-1/3 -translate-y-1/2 absolute z-10 right-0 top-0`}>
                    <LottieWrapper delay="500" thresholdParam="0.3" anim="LogoOO" />
                </div>
                <div className="relative z-20 flex flex-col w-full justify-center items-center mb-10">
                    <div className=" pointer-events-none z-130 top-0 flex flex-row items-center justify-between w-11/12 h-20 md:h-auto p-0 md:py-10">
                        <div className="pointer-events-none w-50 md:w-70 p-5">
                        <LogoGreyEdmond />
                        </div>
                        <motion.div whileHover={{ scale: 1.2 }} transition={{ type: "spring" }} variants={childrenAnim} className="sofia-light h-6 md:h-auto mr-10 md:mr-20 right-0 fixed cursor-pointer pointer-events-auto text-5xl"><Link to="/"><SvgCroix className="w-auto h-full" /></Link></motion.div>
                    </div>
                    <div className="relative flex row-gap-10 flex-col w-full md:w-11/12 md:mb-20">
                        <div className="flex h-full flex-col md:flex-row w-full gap-10 md:gap-20 items-center justify-center">
                            <motion.div variants={childrenAnim} className="relative h-screen-1/2 md:h-auto w-full md:w-3/5">
                                {realisation.frontmatter.thumbnail !== null &&
                                    <div className="relative" style={{ filter: "saturate(110%)" }} >
                                        <div className="absolute z-20 bg-white opacity-25 w-full h-full"></div>
                                        <BackgroundImage
                                            Tag="section"
                                            className="h-screen-1/2 w-full md:h-screen-3/5"
                                            fluid={realisation.frontmatter.thumbnail.childImageSharp.fluid}
                                            backgroundColor={`#CFDEE1`}
                                        >
                                        </BackgroundImage>
                                    </div>
                                }
                            </motion.div>
                            <motion.div variants={childrenAnimOpacity} className="md:h-screen-3/5 p-0 md:p-10 relative w-10/12 md:w-2/5 flex flex-col items-start justify-start text-lg tracking-widest text-grey-edmond sofia-regular">
                                <div className="bg-white mb-10 md:mb-20 p-5 bg-opacity-75 pointer-events-none relative transform md:-translate-x-full left-0 top-0 z-20 flex-col items-center justify-center">
                                    <h1 className="sofia-bold text-lg md:text-xl tracking-widest uppercase text-grey-edmond">{realisation.frontmatter.titre}</h1>
                                    <p className="sofia-bold text-base md:text-lg tracking-widest text-grey-edmond">{realisation.frontmatter.adresse1}</p>
                                    <p className="sofia-bold text-base md:text-lg tracking-widest text-grey-edmond">{realisation.frontmatter.adresse2}</p>
                                    {/* <p className="sofia-bold text-sm tracking-widest text-grey-edmond">{realisation.node.tags}</p> */}
                                </div>
                                <div className="text-left w-full text-base" dangerouslySetInnerHTML={{ __html: realisation.html }} />
                            </motion.div>
                        </div>
                        <div className="flex gap-20  h-full w-full">
                            {realisation.frontmatter.gallerie !== null &&
                                realisation.frontmatter.gallerie.map((el, index) => (
                                    <motion.div variants={childrenAnim} style={{ filter: "saturate(90%)" }} key={index} className="relative w-full md:w-1/2 xxl:w-1/3">
                                        <div className="absolute z-20 bg-white opacity-25 w-full h-full"></div>
                                        <Img fluid={el.image.childImageSharp.fluid}></Img>
                                    </motion.div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <Footer />

            </motion.div>
        </>
    )
}

export const data = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
                html
                frontmatter {
                  path
                  titre
                  tags
                  adresse1
                  adresse2
                  gallerie {
                    image {
                        childImageSharp {
                            fluid( quality: 95, maxWidth: 2000) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                        }  
                    }
                  }
                  thumbnail
                  {
                    childImageSharp {
                      fluid( quality: 95, maxWidth: 2000) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                     }
                  }
                }
              }
    }
    `


export default RealisationPage;
